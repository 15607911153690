body {
  margin: 0;
  padding: 0;
}

/* 
  css fallback is not available on inside React or MterialUI,
  css with fallback should be written here.
  https://github.com/facebook/react/issues/2020
*/
/* .map-container, */
.App {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;  
  /* 
    Tell mobile browser to fill the height with viewport without url header and function footer
    https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
   */
  min-height: -moz-available;
  min-height: -webkit-fill-available;
  min-height: stretch;
  width: 100%;
}

/* 
clickable class is targeted by Google Tag Manager click trigger
pointer-events: none; ensures that gtm does not trigger for clickable children,
this is the only way to consistently get GTM to fire click on desired element
 */
.clickable > * {
  pointer-events: none;
}
.clickable:hover {
  cursor: pointer;
}

/* ----------------------------------------------
 * Generated by Animista 
 * http://animista.net
 * ---------------------------------------------- */

.slide-in-right {
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-left {
	-webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
